<template>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      users: [],
    }
  },
  mounted() {
    const at = this.$route.query.access_token;
    const rt = this.$route.query.refresh_token;
    const mode = this.$route.query.mode;
    
    this.$api('http://server.honey-game.com/auth/account', 'post', {
      'at' : at,
      'rt' : rt,
    }).then((res)=>{

      if( res.result == 1 ) {
        this.users = res.userData;
        useJwt.setToken(res.access_token)
        useJwt.setRefreshToken(res.refresh_token)
        localStorage.setItem('userData', JSON.stringify(this.users))
        
        // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
        if( mode == 'debug' ) {
          this.$router.replace('/debug');
        } else if( mode == 'debug2' ) {
          this.$router.replace('/debug2');
        } else if( mode == 'euro' ) {
          this.$router.replace('/games');
        } else {
          this.$router.replace('/games2');
        }

      } else {
        this.$router.replace('/pages/miscellaneous/not-authorized');
      }
    });
  },
  methods: {
  },
}
</script>